<!--
 * @Descripttion: 
 * @FilePath: /azg/src/views/daiban/confirm/components/Agree/index.vue
 * @Author: 张兴业
 * @Date: 2021-11-12 11:21:09
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-11-12 11:22:51
-->
<template>
  <div class="agreement">
    <van-field name="agree" v-model="agree" class="agreement_filed">
      <template #input>
        <div class="login_argument font_weight400 font12 text_center">
          <div class="clearfix agreement_c">
            <img
              v-if="agree === '1'"
              src="@/assets/img/ditui/select.png"
              alt=""
              class="select_img left"
              @click="unselectedAgreement"
            />
            <img
              v-if="agree === '0'"
              src="@/assets/img/ditui/unselect.png"
              alt=""
              class="select_img left"
              @click="selectedAgreement"
            />
            <span>
              同意
              <span>
                <a href="">用户协议</a>
              </span>
              和
              <span>
                <a href="">隐私政策</a>
              </span>
            </span>
            <!-- <div class="left">
              <span>同意 </span>
              <span class="argu_line colo0" @click.stop="goLogin1"
                >用户协议
              </span>
              <span> 和 </span>
              <span
                class="argu_line colo0"
                @click="$router.push('/agreement/login2')"
                >隐私政策</span
              >
            </div> -->
          </div>
        </div>
      </template>
    </van-field>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      agree: this.value
    };
  },
  methods: {
    selectedAgreement() {
      this.agree = "1";
      this.$emit("input", "1");
    },
    unselectedAgreement() {
      this.agree = "0";
      this.$emit("input", "0");
    },
    goLogin1() {
      // console.log(111);
      this.$router.push("/agreement/login1");
    }
  }
};
</script>

<style lang="less" scoped>
.agreement {
  margin-top: 12px;
}
.agreement_c {
  display: flex;
  flex-direction: row;
  font-size: 12px;
}
.agreement_filed {
  padding: 0;
}
.login_argument {
  margin: 0 auto 0;
  color: rgba(0, 0, 0, 0.5);
  line-height: 20px;
  .select_img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
  .argu_line {
    display: inline-block;
    text-decoration: underline;
  }
}

a {
  color: #000;
}
</style>
